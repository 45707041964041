import { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Grid,
  GridItem,
  VStack,
  Text,
  Button,
  Image,
  Accordion,
  AccordionPanel,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import ApiResponse from "../../services/ApiResponse";
import { Institucion } from "../../services/models/Institucion";
import { useNavigate } from "react-router-dom";
import ServerErrorPage from "../ServerErrorPage";
import UnauthorizedPage from "../UnauthorizedPage";
import config from "../../MapConfig";

const InstitucionesPage = () => {
  const endpoint = "instituciones";
  const [response, setResponse] = useState(new ApiResponse<Institucion[]>());
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInstituciones = async () => {
      const apiResponse = new ApiResponse<Institucion[]>();
      await apiResponse.useFetch(`${endpoint}`, "GET");
      setResponse(apiResponse);
    };

    fetchInstituciones();
  }, []);

  let contentLo = () => {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" thickness="4px" speed="0.65s" color="green.300" />
      </Flex>
    );
  };

  let contentOk = () => {
    return (
      <div>
        <Heading mb={5} color="cyan.200" textAlign="center">Instituciones en Goya</Heading>

        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
          }}
          gap={8}
          px={{ base: 2, md: 8 }}
        >
          {response.data?.map((institucion) => (
            <GridItem
              key={institucion.id}
              bgColor="#063C52"
              p={5}
              borderRadius="md"
              boxShadow="lg"
            >
              <Accordion allowToggle>
                <AccordionItem border="none">
                  <h2>
                    <AccordionButton
                      _expanded={{ bg: "cyan.700", color: "white" }}
                    >
                      <Box
                        flex="1"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                      >
                        <VStack align="center" spacing={2}>
                          {institucion.logo && (
                            <Image
                              src={`${config.API_IMAGES_URL}${institucion.logo}`}
                              alt={`Logo de ${institucion.nombre}`}
                              boxSize="80px"
                              objectFit="cover"
                              borderRadius="full"
                              border="2px solid cyan.400"
                              mb={3}
                            />
                          )}
                          <Text fontSize="lg" fontWeight="bold" color="cyan.200">
                            {institucion.nombre}
                          </Text>
                        </VStack>
                      </Box>
                      <AccordionIcon color="cyan.300" />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} textAlign="center" color="cyan.100">
                    <Text fontSize="sm">
                      <b>Dirección:</b> {institucion.direccion}
                    </Text>
                    {institucion.tel && (
                      <Text fontSize="sm">
                        <b>Teléfono:</b> {institucion.tel}
                      </Text>
                    )}
                    {institucion.gestion && (
                      <Text fontSize="sm">
                        <b>Tipo de Gestión:</b> {institucion.gestion}
                      </Text>
                    )}
                    <Button
                      colorScheme="teal"
                      width="full"
                      mt={2}
                      onClick={() =>
                        navigate(`/instituciones/${institucion.id}`, {
                          state: institucion,
                        })
                      }
                    >
                      Ver detalles
                    </Button>
                    {institucion.pagina && (
                      <Button
                        mt={3}
                        colorScheme="teal"
                        width="full"
                        onClick={() =>
                          window.open(`http://${institucion.pagina}`, "_blank")
                        }
                      >
                        Visitar Página Web
                      </Button>
                    )}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </GridItem>
          ))}
        </Grid>
      </div>
    );
  };

  let contentUn = () => {
    return <UnauthorizedPage />;
  };

  let contentSe = () => {
    return <ServerErrorPage />;
  };

  const content = {
    lo: contentLo(),
    ok: contentOk(),
    un: contentUn(),
    se: contentSe(),
  };

  const getContentType = () => {
    if (response.loading === true) {
      return content.lo;
    } else if (response.responseCode === 200) {
      return content.ok;
    } else if (response.responseCode === 401) {
      return content.un;
    } else {
      return content.se;
    }
  };

  return (
    <Box p={5} minH="100vh" bgColor="#042C3C" color="cyan.100">
      {getContentType()}
    </Box>
  );
};

export default InstitucionesPage;
