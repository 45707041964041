import { Box, Button, Heading, Text } from "@chakra-ui/react"
import { IoReloadCircle } from "react-icons/io5";

const ServerErrorPage = ()=> {
    return(
        <Box textAlign="center" mt={10} minH={"100vh"}>
        <Heading as="h1" size="3xl" color={"green.500"} mb={4}>
            500
          </Heading>
          <Heading as="h1" size="2xl" mb={4}>
            Error al conectar al servidor
          </Heading>
          <Text fontSize="lg" mb={6}>
            Error al traer datos
          </Text>
          <Button colorScheme="green" onClick={()=> window.location.reload()}>
            <IoReloadCircle size={20}></IoReloadCircle> Recargar pagina
          </Button>
        </Box>
    )

}

export default ServerErrorPage;