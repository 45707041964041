import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import InstitucionesPage from "./pages/public/InstitucionesPage";
import LoginPage from "./pages/login/LoginPage";
import HomePage from "./pages/public/HomePage";
import ProtectedRoute from "./services/ProtectedRoute";
import InstitucionesTablePage from "./pages/dashboard/instituciones/InstitucionesTablePage";
import AdminAddPage from "./pages/dashboard/administradores/AdminAddPage";
import DashboardRoutes from "./pages/dashboard/DashboardRoutes";
import InstitucionAddPage from "./pages/dashboard/instituciones/InstitucionAddPage";
import CarrerasTablePage from "./pages/dashboard/carreras/CarrerasTablePage";
import CarreraAddPage from "./pages/dashboard/carreras/CarreraAddPage";
import CarrerasPage from "./pages/public/CarrerasPage";
import CarreraPage from "./pages/public/CarreraPage";
import CarreraEditPage from "./pages/dashboard/carreras/CarreraEditPage";
import InstitucionEditPage from "./pages/dashboard/instituciones/InstitucionEditPage";
import AdminEditPage from "./pages/dashboard/administradores/AdminEditPage";
import AdminsTablePage from "./pages/dashboard/administradores/AdminsTablePage";
import { APIProvider } from "@vis.gl/react-google-maps";
import config from "./MapConfig";
import ServerErrorPage from "./pages/ServerErrorPage";
import UnauthorizedPage from "./pages/UnauthorizedPage";
import NavbarHome from "./components/NavbarHome";
import FooterComponent from "./components/FooterComponent";
import InstitucionPage from "./pages/public/InstitucionPage";
import NotFoundPage from "./pages/NotFoundPage";
import BadRequestPage from "./pages/BadRequestPage";
import InfoPage from "./pages/public/InfoPage";


// TODO: GENERAL: 1- Mapas; 2- Ordenar x prioridad carreras ✅; 3- Manejo de errores (no mostrar msj feos) 🔛; 4- SACAR DECODED TOKEN, NO HACE FALTA NO? DESDE EL BACK TOKEN EXPIRADO Y ESO

function App() {
  return (
    <ChakraProvider>
      <APIProvider apiKey={config.API_MAPS_KEY} language="es">
        <BrowserRouter>
          <NavbarHome />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/carreras" element={<CarrerasPage />} />
            <Route path="/carreras/:id" element={<CarreraPage />} />
            <Route path="/instituciones/:id" element={<InstitucionPage />} />
            <Route path="/instituciones" element={<InstitucionesPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/unauthorized" element={<UnauthorizedPage />} />
            <Route path="/servererror" element={<ServerErrorPage />} />
            <Route path="/info" element={<InfoPage />} />

            <Route path="/dashboard/*" element={<DashboardRoutes />} />

            <Route
              path="/dashboard/instituciones"
              element={
                <ProtectedRoute roles={[1]}>
                  <InstitucionesTablePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/instituciones-crear"
              element={
                <ProtectedRoute roles={[1]}>
                  <InstitucionAddPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/instituciones-editar/:id"
              element={
                <ProtectedRoute roles={[1]}>
                  <InstitucionAddPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/administradores"
              element={
                <ProtectedRoute roles={[1]}>
                  <AdminsTablePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/usuarios-crear"
              element={
                <ProtectedRoute roles={[1]}>
                  <AdminAddPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/usuarios-editar/:id"
              element={
                <ProtectedRoute roles={[1]}>
                  <AdminAddPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/carreras"
              element={
                <ProtectedRoute roles={[2]}>
                  <CarrerasTablePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/carreras-crear"
              element={
                <ProtectedRoute roles={[2]}>
                  <CarreraAddPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/carreras-editar/:id"
              element={
                <ProtectedRoute roles={[2]}>
                  <CarreraAddPage />
                </ProtectedRoute>
              }
            />
            <Route path="/badrequest" element={<BadRequestPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <FooterComponent />
        </BrowserRouter>
      </APIProvider>
    </ChakraProvider>
  );
}

export default App;
