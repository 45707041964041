import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Heading,
  Text,
  Link,
  Spinner,
  VStack,
  HStack,
  Grid,
  Image,
  Flex,
  Container,
  GridItem,
  Card,
  CardBody,
  Badge,
  Divider,
  useColorMode,
  useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import NavbarHome from "../../components/NavbarHome";
import ApiResponse from "../../services/ApiResponse";
import { Carrera } from "../../services/models/Carrera";
import UnauthorizedPage from "../UnauthorizedPage";
import ServerErrorPage from "../ServerErrorPage";
import {
  IoCalendarClearOutline,
  IoCallOutline,
  IoGlobeOutline,
  IoLocateOutline,
  IoPersonOutline,
  IoSchoolOutline,
  IoTimeOutline,
} from "react-icons/io5";
import MapConfig from "../../MapConfig";

function CarreraPage() {
  const endpoint = "carreras";
  const { id } = useParams();
  const [response, setResponse] = useState(new ApiResponse<Carrera>());
  const cardBg = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.600", "gray.300");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  useEffect(() => {
    const fetchCarrera = async () => {
      const apiResponse = new ApiResponse<Carrera>();
      await apiResponse.useFetch(`${endpoint}/${id}`, "GET");
      setResponse(apiResponse);
      console.log("apiResponse", apiResponse);
    };
    fetchCarrera();
  }, []);

  const { data } = response;

  let contentLo = () => {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" thickness="4px" speed="0.65s" color="green.300" />
      </Flex>
    );
  };

function formatearFecha(fechaISO: string) {
  const fecha = new Date(fechaISO);
  const fechaFormateada = fecha.toLocaleDateString('es-ES', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });
  return fechaFormateada;
}

  let contentOk = () => {
    return (
      data && (
        <Container maxW="container.xl">
          <Grid templateColumns={{ base: "1fr", lg: "2fr 1fr" }} gap={8}>
            <GridItem>
              <Card bg={cardBg} shadow="lg" borderRadius="xl">
                <CardBody>
                  <VStack align="stretch" spacing={6}>
                    <Box>
                      <Badge colorScheme="green" fontSize="sm" mb={2}>
                        {data.modalidad}
                      </Badge>
                      <Heading size="lg" mb={2}>
                        {data.nombre}
                      </Heading>
                      <Text color={textColor} fontSize="md">
                        Tipo de Carrera: {data.tipo}
                      </Text>
                    </Box>

                    <Box>
                      <Heading size="sm" mb={3}>
                        Descripción
                      </Heading>
                      <Text color={textColor}>{data.descripcion}</Text>
                    </Box>

                    <Divider borderColor={borderColor} />

                    <VStack align="stretch" spacing={4}>
                      <HStack>
                        <IoTimeOutline size={20} />
                        <Text fontWeight="semibold">Duración:</Text>
                        <Text color={textColor}>
                          {" "}
                          {data.duracion_anios > 0 &&
                            `${data.duracion_anios} ${
                              data.duracion_anios === 1 ? "año" : "años"
                            }`}{" "}
                          {data.duracion_anios > 0 &&
                            data.duracion_meses > 0 &&
                            "y "}
                          {data.duracion_meses > 0 &&
                            `${data.duracion_meses} ${
                              data.duracion_meses === 1 ? "mes" : "meses"
                            }`}
                        </Text>
                      </HStack>

                      <HStack>
                        <IoPersonOutline size={20} />
                        <Text fontWeight="semibold">Cupo:</Text>
                        <Text color={textColor}>
                          {data.cupo || "0"} alumnos
                        </Text>
                      </HStack>

                      <HStack>
                        <IoCalendarClearOutline size={20} />
                        <Text fontWeight="semibold">Fecha de Inscripción:</Text>
                        <Text color={textColor}>
                          {formatearFecha(data.fecha_inscripcion) || ""}
                        </Text>
                      </HStack>

                      <HStack>
                        <IoGlobeOutline size={20} />
                        <Text fontWeight="semibold">Link de Inscripción:</Text>
                        <Text color={textColor}>
                        <Link
                          color="blue.500"
                          href={data.inscripcion}
                          isExternal
                        >
                          {data.inscripcion}
                        </Link>
                        </Text>
                      </HStack>

                      <HStack>
                        <Accordion allowToggle>
                          <AccordionItem>
                            <h2>
                              <AccordionButton>
                                <Flex
                                  as="span"
                                  flex="1"
                                  textAlign="left"
                                  alignItems="center"
                                >
                                  <Box as="span" mr={2}>
                                    <IoSchoolOutline size={20} />
                                  </Box>
                                  Plan de Estudio
                                </Flex>
                                <AccordionIcon />
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
  {data.plan_de_estudio ? (
    data.plan_de_estudio.split(/(?:\r\n|\r|\n)/).map((line, index) => (
      <Text key={index} mb={1}>
        {line.trim()}
      </Text>
    ))
  ) : (
    <Text>No hay plan de estudio disponible.</Text>
  )}
</AccordionPanel>

                          </AccordionItem>
                        </Accordion>
                      </HStack>
                    </VStack>
                  </VStack>
                </CardBody>
              </Card>
            </GridItem>

            {/* Institution Details Section */}
            <GridItem>
              <Card bg={cardBg} shadow="lg" borderRadius="xl">
                <CardBody>
                  <VStack align="center" spacing={6}>
                    <Image
                      src={`${MapConfig.API_IMAGES_URL}${data.institucion_logo}`}
                      alt="Instituto Tecnológico Logo"
                      width={200}
                      height={200}
                    />

                    <VStack align="center" spacing={2}>
                      <Heading size="md">{data.institucion_nombre}</Heading>
                    </VStack>

                    <Divider borderColor={borderColor} />

                    <VStack align="stretch" spacing={4} width="100%">
                      <HStack>
                        <IoLocateOutline size={20} />
                        <Text fontWeight="semibold">Dirección:</Text>
                        <Text color={textColor}>
                          {data.institucion_direccion}
                        </Text>
                      </HStack>

                      <HStack>
                        <IoCallOutline size={20} />
                        <Text fontWeight="semibold">Teléfono:</Text>
                        <Link
                          color="blue.500"
                          href={`tel:${data.institucion_tel}`}
                        >
                          {data.institucion_tel}
                        </Link>
                      </HStack>

                      <HStack>
                        <IoGlobeOutline size={20} />
                        <Text fontWeight="semibold">Web:</Text>
                        <Link
                          color="blue.500"
                          href={data.institucion_pagina}
                          isExternal
                        >
                          {data.institucion_pagina}
                        </Link>
                      </HStack>
                    </VStack>
                  </VStack>
                </CardBody>
              </Card>
            </GridItem>
          </Grid>
        </Container>
      )
    );
  };

  let contentUn = () => {
    return <UnauthorizedPage></UnauthorizedPage>;
  };

  let contentSe = () => {
    return <ServerErrorPage></ServerErrorPage>;
  };

  const content = {
    lo: contentLo(),
    ok: contentOk(),
    un: contentUn(),
    se: contentSe(),
  };
  const getContentType = () => {
    if (response.loading === true) {
      return content.lo;
    } else {
      if (response.responseCode === 200) {
        return content.ok;
      } else if (response.responseCode === 401) {
        return content.un;
      } else if (response.responseCode === 999) {
        return content.se;
      } else {
        return content.se;
      }
    }
  };

  return (
    <div>
      <Box m={5} minH={"100vh"}>
        {getContentType()}
      </Box>
    </div>
  );
}

export default CarreraPage;
