"use client";

import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  HStack,
  Badge,
  useColorModeValue,
  Link,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
  Spinner,
} from "@chakra-ui/react";
import {
  IoLocationOutline,
  IoCallOutline,
  IoGlobeOutline,
  IoSchoolOutline,
  IoChevronForward,
} from "react-icons/io5";
import InstitucionDetailMap from "../../components/InstitucionDetailMap";
import { Institucion } from "../../services/models/Institucion";
import ApiResponse from "../../services/ApiResponse";
import { useLocation, useNavigate } from "react-router-dom";
import { Carrera } from "../../services/models/Carrera";
import UnauthorizedPage from "../UnauthorizedPage";
import ServerErrorPage from "../ServerErrorPage";
import BadRequestPage from "../BadRequestPage";

export default function InstitucionDetails() {
  const navigate = useNavigate();
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const cardBg = useColorModeValue("white", "gray.800");
  const hoverBg = useColorModeValue("gray.100", "gray.700");
  const location = useLocation();
  const institucion = location.state ? (location.state as Institucion) : null;
  const [response, setResponse] = useState(new ApiResponse<Carrera[]>());

  useEffect(() => {
    const fetchCarreras = async () => {
      if (!institucion) {
        navigate("/badrequest");
        return;
      }
      const apiResponse = new ApiResponse<Carrera[]>();
      await apiResponse.useFetch(
        `carreras/institucion/${institucion.id}`,
        "GET"
      );
      setResponse(apiResponse);
    };
    fetchCarreras();
  }, []);

  let contentLo = () => {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" thickness="4px" speed="0.65s" color="green.300" />
      </Flex>
    );
  };

  let contentOk = () => {
    return (
      <VStack spacing={3} align="stretch">
        {response.data?.length === 0 ? (
          <Card>
            <CardBody>
              <VStack spacing={3} align="center" py={6}>
                <IoSchoolOutline size={40} color="gray" />
                <Text color="gray.500">No hay carreras disponibles</Text>
                <Text fontSize="sm" color="gray.400">
                  No se encontraron carreras registradas en el sistema
                </Text>
              </VStack>
            </CardBody>
          </Card>
        ) : (
          response.data?.map((carrera) => (
            <Card
              key={carrera.id}
              as="button"
              onClick={() => navigate(`/carreras/${carrera.id}`)}
              _hover={{ bg: hoverBg }}
              transition="background 0.2s"
            >
              <HStack spacing={1}>
                <CardBody>
                  <Flex justify="space-between" align="center">
                    <HStack spacing={3}>
                      <IoSchoolOutline size={20} />
                      <VStack align="start" spacing={0}>
                        <Text fontWeight="semibold">{carrera.nombre}</Text>
                        <Badge colorScheme={"green"}>{carrera.tipo}</Badge>
                        <Text fontWeight="semibold">
                          {" "}
                          {carrera.duracion_anios > 0 &&
                            `${carrera.duracion_anios} ${
                              carrera.duracion_anios === 1 ? "año" : "años"
                            }`}{" "}
                          {carrera.duracion_anios > 0 &&
                            carrera.duracion_meses > 0 &&
                            "y "}
                          {carrera.duracion_meses > 0 &&
                            `${carrera.duracion_meses} ${
                              carrera.duracion_meses === 1 ? "mes" : "meses"
                            }`}
                        </Text>
                      </VStack>
                    </HStack>
                  </Flex>
                </CardBody>
                <Flex justify="end" align="center">
                  <IoChevronForward size={20} />
                </Flex>
              </HStack>
            </Card>
          ))
        )}
      </VStack>
    );
  };

  let contentUn = () => {
    return <UnauthorizedPage></UnauthorizedPage>;
  };

  let contentSe = () => {
    return <ServerErrorPage></ServerErrorPage>;
  };
  const content = {
    lo: contentLo(),
    ok: contentOk(),
    un: contentUn(),
    se: contentSe(),
  };

  const getContentType = () => {
    if (response.loading === true) {
      return content.lo;
    } else {
      if (response.responseCode === 200) {
        return content.ok;
      } else if (response.responseCode === 401) {
        return content.un;
      } else if (response.responseCode === 999) {
        return content.se;
      } else {
        return content.se;
      }
    }
  };

  return (
    <Box bg={bgColor} minH="100vh" py={10}>
      {institucion ? (
        <Container maxW="container.xl">
          <VStack spacing={8} align="stretch">
            <Heading size="2xl" textAlign="center">
              {institucion.nombre}
            </Heading>

            <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={6}>
              <GridItem>
                <Box
                  bg={cardBg}
                  p={6}
                  borderRadius="lg"
                  shadow="md"
                  height="100%"
                >
                  <VStack align="stretch" p={5} spacing={4}>
                    <HStack>
                      <IoLocationOutline size={24} />
                      <Text>{institucion.direccion}</Text>
                    </HStack>
                    <HStack>
                      <IoCallOutline size={24} />
                      <Text>{institucion.tel || "N/A"}</Text>
                    </HStack>
                    <HStack>
                      <IoGlobeOutline size={24} />
                      <Link
                        href={institucion.pagina || ""}
                        isExternal
                        color="blue.500"
                      >
                        {institucion.pagina || "N/A"}
                      </Link>
                    </HStack>
                  </VStack>
                </Box>
              </GridItem>

              <GridItem>
                <Box
                  height="100%"
                  minHeight="300px"
                  borderRadius="lg"
                  overflow="hidden"
                >
                  <InstitucionDetailMap
                    nombre={institucion.nombre}
                    lati={institucion.ubicacion_lat || 0}
                    lngi={institucion.ubicacion_long || 0}
                  ></InstitucionDetailMap>
                </Box>
              </GridItem>
            </Grid>

            <Box bg={cardBg} p={6} borderRadius="lg" shadow="md">
              <Heading size="lg" mb={4}>
                Carreras disponibles
              </Heading>
              {getContentType()}
            </Box>
          </VStack>
        </Container>
      ) : (
        <BadRequestPage></BadRequestPage>
      )}
    </Box>
  );
}
