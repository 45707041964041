// TODO: manejar error de si ya existe el mismo mail!!! --> = edit
import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  Heading,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import ApiResponse from "../../../services/ApiResponse";
import {
  Administrador,
  AdministradorCreate,
  Roles,
} from "../../../services/models/Administrador";
import SuperUserDashboard from "../SuperUserDashboard";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { Institucion } from "../../../services/models/Institucion";
import handleApiError from "../../../utils/ApiErrorHandler";
import { ApiValidationResponse } from "../../../services/models/ApiValidationResponse";

const AdminAddPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const params = useParams();
  const [instituciones, setInstituciones] = useState<Institucion[]>([]);
  const [rol, setRol] = useState<Roles>(Roles.Admin);
  const [loading, setLoading] = useState(true);

  const nombreRef = useRef<HTMLInputElement>(null);
  const correoRef = useRef<HTMLInputElement>(null);
  const rolRef = useRef<HTMLSelectElement>(null);
  const idInstitucionRef = useRef<HTMLSelectElement>(null);
  const claveRef = useRef<HTMLInputElement>(null);
  const [show, setShow] = useState(false);
  const handlePasswordToggle = () => setShow(!show);

  useEffect(() => {
    const fetchInstituciones = async () => {
      const apiInstitucionesResponse = new ApiResponse();
      await apiInstitucionesResponse.useFetch("instituciones", "GET");

      if (
        apiInstitucionesResponse.error == null &&
        Array.isArray(apiInstitucionesResponse.data)
      ) {
        setInstituciones(apiInstitucionesResponse.data);
      } else {
        toast({
          title: "Error",
          description: `Ocurrió un error al cargar las instituciones: ${apiInstitucionesResponse.error}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      setLoading(false);
    };

    fetchInstituciones();
  }, [toast]);

  useEffect(() => {
    if (params.id) {
      const fetchUserdata = async () => {
        const response = new ApiResponse<Administrador>();
        await response.useFetch(`usuarios/${params.id}`, "GET");
        if (response.error == null) {
          if (nombreRef.current && response.data) {
            nombreRef.current.value = response.data.nombre;
          }
          if (correoRef.current && response.data) {
            correoRef.current.value = response.data.correo;
          }
          if (idInstitucionRef.current && response.data) {
            idInstitucionRef.current.value = String(response.data?.institucion_id)
          }
          if (claveRef.current && response.data) {
            claveRef.current.value = response.data.clave;
          }
          setRol(Number(response.data?.rol_id) as Roles);
          setLoading(false);
        }
        else{
          toast({
            title: "Error",
            description: `Ocurrió un error al cargar los datos del usuario: ${response.error}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          setLoading(false);
        }
      };
      fetchUserdata();
    }
  }, [params.id && instituciones]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const idInstitucion =
      rol !== Roles.SuperUser && idInstitucionRef.current?.value
        ? parseInt(idInstitucionRef.current?.value)
        : undefined;

    const formData: AdministradorCreate = {
      nombre: nombreRef.current?.value || "",
      correo: correoRef.current?.value || "",
      rol_id: rol,
      institucion_id: idInstitucion,
      clave: claveRef.current?.value || "",
    };

    const apiPostResponse = new ApiResponse<Administrador>();
    params.id
      ? await apiPostResponse.useFetch(
          `usuarios/${params.id}`,
          "PATCH",
          formData
        )
      : await apiPostResponse.useFetch("usuarios", "POST", formData);

    if (apiPostResponse.error == null) {
      toast({
        title: "Administrador creado",
        description: params.id ? "Datos actualizados con éxito." : "Usuario creado con éxito.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate("/dashboard/administradores");
    } else {
      if (
        typeof apiPostResponse.error === "object" &&
        apiPostResponse.error !== null
      ) {
        if ("errors" in apiPostResponse.error) {
          handleApiError(apiPostResponse.error as ApiValidationResponse, toast);
        } else if ("message" in apiPostResponse.error) {
          const { message } = apiPostResponse.error;
          toast({
            title: "Error",
            description: message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Error desconocido",
            description: "Ocurrió un problema inesperado.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        toast({
          title: "Error al actualizar la carrera.",
          description:
            apiPostResponse.error ||
            "Ocurrió un problema al actualizar la carrera.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <div>
      <Box maxW="md" mx="auto" mt={8} p={6} borderWidth={1} borderRadius="lg">
        <Heading mb={6} textAlign="center" size="lg">
          {params.id ? "Editar" : "Crear"} Usuario
        </Heading>
        <form onSubmit={handleSubmit}>
          <VStack spacing={5}>
            <FormControl id="nombre" isRequired>
              <FormLabel>Nombre</FormLabel>
              <Input type="text" ref={nombreRef} defaultValue="" />
            </FormControl>

            <FormControl id="correo" isRequired>
              <FormLabel>Correo Electrónico</FormLabel>
              <Input type="email" ref={correoRef} defaultValue="" />
            </FormControl>

            <FormControl id="rol" isRequired>
              <FormLabel>Rol</FormLabel>
              <Select
                ref={rolRef}
                value={rol}
                onChange={(e) => setRol(Number(e.target.value) as Roles)}
              >
                <option value={Roles.SuperUser}>Super User</option>
                <option value={Roles.Admin}>Admin</option>
                <option value={Roles.Assistant}>Asistant</option>
              </Select>
            </FormControl>

            {rol !== Roles.SuperUser && (
              <FormControl id="id_institucion" isRequired>
                <FormLabel>Institución</FormLabel>
                <Select
                  ref={idInstitucionRef}
                  placeholder="Selecciona una institución"
                  isDisabled={loading}
                >
                  {instituciones.map((institucion) => (
                    <option key={institucion.id} value={institucion.id}>
                      {institucion.nombre}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
            <FormControl id="clave" isRequired>
              <FormLabel>Contraseña</FormLabel>
              <InputGroup>
                <Input
                  type={show ? "text" : "password"}
                  ref={claveRef}
                  defaultValue=""
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handlePasswordToggle}>
                    {show ? <IoEyeOff /> : <IoEye />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <Button
              type="submit"
              colorScheme="teal"
              size="lg"
              width="full"
              isLoading={loading}
            >
              {params.id ? "Guardar cambios" : "Crear usuario"}
            </Button>
            <Button
              type="button"
              onClick={() => navigate("/dashboard/administradores")}
              colorScheme="gray"
              size="lg"
              width="full"
              isLoading={loading}
            >
              Cancelar
            </Button>
          </VStack>
        </form>
      </Box>
    </div>
  );
};

export default AdminAddPage;
