import { IoClose, IoFilterSharp, IoPersonCircle } from "react-icons/io5";
import {
  Button,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
} from "@chakra-ui/react";
import {
  Box,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { getDecodedToken } from "../services/Token";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Links = [
  { name: "Carreras", path: "/carreras" },
  { name: "Instituciones", path: "/instituciones" },
  { name: "Quienes somos", path: "/info" },
];

const SuperUserLinks = [
  { name: "Usuarios", path: "/dashboard/administradores" },
  //{ name: "Administrar carreras", path: "/dashboard/carreras" },
  { name: "Administrar instituciones", path: "/dashboard/instituciones" },
];

const AdminLinks = [
  { name: "Administrar carreras", path: "/dashboard/carreras" },
];

interface NavLinkProps {
  name: string;
  path: string;
}

const NavLinks = ({ name, path }: NavLinkProps) => {
  return (
    <Box
      px={2}
      py={1}
      rounded={"md"}
      _hover={{
        textDecoration: "none",
        bg: useColorModeValue("green.500", "gray.700"),
      }}
    >
      <Link
        href={path}
        textDecoration="none"
        color="white"
        fontWeight="medium"
        fontSize="lg"
        _hover={{
          color: "gray.300",
        }}
        px={2}
        py={1}
        borderRadius="md"
      >
        {name}
      </Link>
    </Box>
  );
};

export default function NavbarHome() {
  const decoded = getDecodedToken();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  useEffect(() => {}, [decoded]);

  return (
    <>
      <Box bg={useColorModeValue("#042C3C", "gray.900")} px={4}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          {/* Botón para abrir/cerrar menú en móvil */}
          <IconButton
            size={"md"}
            icon={isOpen ? <IoClose /> : <HamburgerIcon />} // Cambia entre iconos
            aria-label={"Toggle Navigation"}
            display={{ md: "none" }} // Sólo visible en pantallas pequeñas
            onClick={isOpen ? onClose : onOpen}
            bg="transparent" // Fondo transparente para un diseño limpio
            _hover={{ bg: "green.500", color: "white" }} // Efecto hover
            color={useColorModeValue("white", "gray.200")} // Adaptación a tema oscuro/claro
          />
          <HStack spacing={8} alignItems={"center"}>
            {/* Logo */}
            <Box>
              <Link href={"/"}>
                <img
                  src="/logomunicipio.png"
                  alt="Logo Goya"
                  width="80"
                  height="80"
                />
              </Link>
            </Box>
            {/* Navegación para pantallas grandes */}
            <HStack
              as={"nav"}
              spacing={4}
              display={{ base: "none", md: "flex" }}
            >
              {Links.map((link) => (
                <NavLinks key={link.name} name={link.name} path={link.path} />
              ))}
              {decoded &&
                decoded.rol_id === 1 &&
                SuperUserLinks.map((link) => (
                  <NavLinks key={link.name} name={link.name} path={link.path} />
                ))}
              {decoded &&
                decoded.rol_id === 2 &&
                AdminLinks.map((link) => (
                  <NavLinks key={link.name} name={link.name} path={link.path} />
                ))}
            </HStack>
          </HStack>
          {/* Perfil del usuario */}
          <Flex alignItems={"center"}>
            {decoded ? (
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                >
                  <IoPersonCircle size={45} color="white" />
                </MenuButton>
                <MenuList>
                  <MenuDivider />
                  <MenuItem onClick={handleLogout} color="red">
                    Cerrar Sesión
                  </MenuItem>
                </MenuList>
              </Menu>
            ) : (
              <NavLinks name="Ingresar" path="/login" />
            )}
          </Flex>
        </Flex>

        {/* Menú colapsable en móvil */}
        {isOpen ? (
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              {Links.map((link) => (
                <NavLinks key={link.name} name={link.name} path={link.path} />
              ))}
              {decoded &&
                decoded.rol_id === 1 &&
                SuperUserLinks.map((link) => (
                  <NavLinks key={link.name} name={link.name} path={link.path} />
                ))}
              {decoded &&
                decoded.rol_id === 2 &&
                AdminLinks.map((link) => (
                  <NavLinks key={link.name} name={link.name} path={link.path} />
                ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
}
