export interface Administrador {
  id: number;                      
  rol_id: Roles;      
  nombre: string;                 
  correo: string;                
  institucion_id?: number;        
  clave: string;                 
  estado: number; 
}

export enum Roles {
  SuperUser = 1,
  Admin = 2,
  Assistant = 3
}

export type AdministradorSinClave = Omit<Administrador, 'clave'> & {
  institucion_nombre: string;
};

export type AdministradorCreate = Omit<Administrador, 'id' | 'estado'>;

export interface AdministradorUpdate {
  rol_id?: Roles;                
  nombre?: string;        
  correo?: string;          
  clave?: string;          
  institucion_id?: number;  
}