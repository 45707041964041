import React, { useEffect, useState } from "react";
import TableCRUD from "../../../components/TableCRUD";
import ApiResponse from "../../../services/ApiResponse";
import SuperUserDashboard from "../SuperUserDashboard";
import { Usuario } from "../../../../../server/src/models/usuario.model";
import { Box, Flex, Skeleton, Spinner, Stack } from "@chakra-ui/react";
import UnauthorizedPage from "../../UnauthorizedPage";
import ServerErrorPage from "../../ServerErrorPage";

function AdminsTablePage() {
  const headers = [
    "ID",
    "Rol",
    "Nombre",
    "Correo",
    "ID Institución",
    "Estado",
    "Institución",
  ];

  const [response, setResponse] = useState(new ApiResponse<Usuario[]>());

  useEffect(() => {
    const fetchAdministradores = async () => {
      const apiResponse = new ApiResponse<Usuario[]>();
      await apiResponse.useFetch("usuarios", "GET");
      if (apiResponse.data) {
        apiResponse.data.sort((a, b) => a.nombre.localeCompare(b.nombre));
      }
      setResponse(apiResponse);
    };

    fetchAdministradores();
  }, []);

  let contentLo = () => {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" thickness="4px" speed="0.65s" color="green.300" />
      </Flex>
    );
  };

  let contentOk = () => {
    return(
    <TableCRUD
    tableName="Usuarios"
    headers={headers}
    data={response.data || []}
    infoColumn="nombre"
  />);
  }

  let contentUn = () => {
    return <UnauthorizedPage></UnauthorizedPage>;
  };

  let contentSe = () => {
    return <ServerErrorPage></ServerErrorPage>;
  };

  const content = {
    lo: contentLo(),
    ok: contentOk(),
    un: contentUn(),
    se: contentSe(),
  };

  const getContentType = () => {
    if (response.loading === true) {
      return content.lo;
    } else {
      if (response.responseCode === 200) {
        return content.ok;
      } else if (response.responseCode === 401) {
        return content.un;
      } else if (response.responseCode === 999) {
        return content.se;
      } else {
        return content.se;
      }
    }
  };

  return (
    <Box p={5} minH={"100vh"}>
      {getContentType()}
      </Box>
  );
}

export default AdminsTablePage;
