// src/Unauthorized.tsx

import React from 'react';
import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const UnauthorizedPage: React.FC = () => {
    const navigate = useNavigate();

  return (
      <Box textAlign="center" mt={10} minH={"100vh"}>
      <Heading as="h1" size="3xl" color={"teal.500"} mb={4}>
          403
        </Heading>
        <Heading as="h1" size="2xl" mb={4}>
          Acceso no permitido
        </Heading>
        <Text fontSize="lg" mb={6}>
          No tienes permisos para acceder a esta pagina
        </Text>
        <Button colorScheme="teal" onClick={()=> navigate('/')}>
          Volver a inicio
        </Button>
      </Box>
  );
};

export default UnauthorizedPage;
