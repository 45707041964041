const API_MAPS_KEY = process.env.REACT_APP_MAPS_KEY;
if (!API_MAPS_KEY) {
  throw new Error("REACT_APP_MAPS_KEY is not set");
};

const MAP_ID = process.env.REACT_APP_MAP_ID;
if (!MAP_ID) {
  throw new Error("REACT_APP_MAP_ID is not set");
}

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
if (!API_BASE_URL) {
  throw new Error("REACT_API_BASE_URL is not set");
}

const API_IMAGES_URL = process.env.REACT_APP_API_IMAGES_URL;
if (!API_IMAGES_URL) {
  throw new Error("REACT_APP_API_IMAGES_URL is not set");
}

export default { API_MAPS_KEY, MAP_ID, API_BASE_URL, API_IMAGES_URL };
