import { useEffect, useRef, useState } from "react";
import {
  Box,
  Heading,
  Input,
  VStack,
  Text,
  Button,
  Flex,
  RadioGroup,
  Radio,
  Spinner,
  IconButton,
} from "@chakra-ui/react";
import NavbarHome from "../../components/NavbarHome";
import ApiResponse from "../../services/ApiResponse";
import { AddIcon, ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { Carrera } from "../../services/models/Carrera";
import UnauthorizedPage from "../UnauthorizedPage";
import ServerErrorPage from "../ServerErrorPage";

const CarrerasPage = () => {
  const endpoint = "carreras";
  const navigate = useNavigate();
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const [response, setResponse] = useState(new ApiResponse<Carrera[]>());
  const [selectedModalidad, setSelectedModalidad] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage] = useState(10);
  const [filteredCarreras, setFilteredCarreras] = useState<Carrera[]>([]);
  const modalidadOptions = ["Todas", "Presencial", "Virtual", "Semipresencial"];

  useEffect(() => {
    const fetchCarreras = async () => {
      const apiResponse = new ApiResponse<Carrera[]>();
      await apiResponse.useFetch(`${endpoint}`, "GET");
      setResponse(apiResponse);
      setFilteredCarreras(apiResponse.data || []);
    };

    fetchCarreras();
  }, []);

  const normalizeText = (text: string) => {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  const handleSearch = () => {
    if (searchInputRef.current) {
      const searchTerm = normalizeText(searchInputRef.current.value);
      const filtered =
        response.data
          ?.filter(
            (carrera) =>
              normalizeText(carrera.nombre).includes(searchTerm) &&
              (selectedModalidad
                ? carrera.modalidad === selectedModalidad
                : true)
          )
          .sort((a, b) => {
            if (a.prioridad === b.prioridad) {
              return a.nombre.localeCompare(b.nombre);
            }
            return a.prioridad - b.prioridad;
          }) || [];
      setFilteredCarreras(filtered);
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    const searchTerm = searchInputRef.current
      ? normalizeText(searchInputRef.current.value)
      : "";
    const filtered =
      response.data
        ?.filter(
          (carrera) =>
            normalizeText(carrera.nombre).includes(searchTerm) &&
            (selectedModalidad ? carrera.modalidad === selectedModalidad : true)
        )
        .sort((a, b) => {
          if (a.prioridad === b.prioridad) {
            return a.nombre.localeCompare(b.nombre);
          }
          return b.prioridad - a.prioridad;
        }) || [];
    setFilteredCarreras(filtered);
    setCurrentPage(1);
  }, [selectedModalidad, response.data]);

  const indexOfLastCarrera = currentPage * resultsPerPage;
  const indexOfFirstCarrera = indexOfLastCarrera - resultsPerPage;
  const currentCarreras = filteredCarreras.slice(
    indexOfFirstCarrera,
    indexOfLastCarrera
  );
  const totalPages = Math.ceil(filteredCarreras.length / resultsPerPage);

  let contentLo = () => {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" thickness="4px" speed="0.65s" color="green.300" />
      </Flex>
    );
  };
  const contentOk = () => (
    <>
      <Heading mb={5} textAlign="center">
        Formación Académica en Goya
      </Heading>
      <Flex mb={5} wrap="wrap" justify="center">
        <Input
          placeholder="Buscar por nombre de carrera..."
          ref={searchInputRef}
          onKeyDown={(e) => e.key === "Enter" && handleSearch()}
          mb={5}
          width={{ base: "100%", md: "60%" }}
        />
        <Button
          onClick={handleSearch}
          colorScheme="cyan"
          ml={{ base: 0, md: 2 }}
          mt={{ base: 2, md: 0 }}
        >
          Buscar
        </Button>
      </Flex>

      <Flex
        mb={5}
        wrap="wrap"
        justify="space-between"
        flexDirection={{ base: "column", md: "row" }}
      >
        <VStack
          align="start"
          mb={{ base: 5, md: 0 }}
          mr={{ base: 0, md: 10 }}
          width={{ base: "100%", md: "auto" }}
        >
          <Text fontWeight="bold">Filtrar por Modalidad:</Text>
          <RadioGroup onChange={setSelectedModalidad} value={selectedModalidad}>
            {modalidadOptions.map((option) => (
              <Radio
                key={option}
                value={option === "Todas" ? "" : option}
                mb={2}
                colorScheme="teal"
              >
                {option}
              </Radio>
            ))}
          </RadioGroup>
        </VStack>

        <Flex direction="column" width="100%">
          {currentCarreras.length === 0 ? (
            <Text fontWeight="medium" color="red.600">
              No se encontraron resultados.
            </Text>
          ) : (
            currentCarreras.map((carrera) => (
              <Flex
                key={carrera.id}
                bg="#063C52"
                p={4}
                mb={2}
                borderRadius="md"
                boxShadow="lg"
                alignItems="flex-start"
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <Box>
                  <Text fontWeight="bold" fontSize="lg">
                    {carrera.nombre}
                  </Text>
                  <Text fontWeight="medium" color="teal.500">
                    {carrera.institucion_nombre}
                  </Text>
                  <Text>Tipo de carrera: {carrera.tipo}</Text>
                  <Text>
                    Duración: {carrera.duracion_anios} año(s){" "}
                    {carrera.duracion_meses} mes(es)
                  </Text>
                  <Text>Modalidad: {carrera.modalidad}</Text>
                </Box>
                <Button
                  onClick={() => navigate(`/${endpoint}/${carrera.id}`)}
                  leftIcon={<AddIcon />}
                  colorScheme="teal"
                >
                  Información
                </Button>
              </Flex>
            ))
          )}
        </Flex>
      </Flex>

      {currentCarreras.length > 0 && (
        <Flex justify="center" align="center" mt={4} wrap="wrap">
          <IconButton
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            isDisabled={currentPage === 1}
            icon={<ArrowLeftIcon />}
            aria-label="Página anterior"
            mr={2}
          />
          <Text mr={5} ml={5}>
            Página {currentPage} de {totalPages}
          </Text>
          <IconButton
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            isDisabled={currentPage === totalPages}
            icon={<ArrowRightIcon />}
            aria-label="Siguiente página"
          />
        </Flex>
      )}
    </>
  );

  const getContentType = () => {
    if (response.loading) return contentLo();
    return response.responseCode === 200 ? (
      contentOk()
    ) : response.responseCode === 401 ? (
      <UnauthorizedPage />
    ) : (
      <ServerErrorPage />
    );
  };

  return (
    <Box p={5} bgColor="#042C3C" color="cyan.100" minH="100vh">
      {getContentType()}
    </Box>
  );
};

export default CarrerasPage;
