import {
    Box,
    Container,
    Flex,
    Link,
    Stack,
    Text,
    VStack,
    useColorModeValue,
  } from '@chakra-ui/react'
  import { 
    IoLogoFacebook,
    IoLogoTwitter,
    IoLogoLinkedin,
    IoMail,
    IoLogoXbox
  } from "react-icons/io5"
  
  export default function FooterComponent() {
    const footerBg = useColorModeValue('gray.100', 'gray.800')
    const textColor = useColorModeValue('gray.600', 'gray.300')
  
    return (
      <Box as="footer" bg={footerBg} py={8}>
        <Container maxW="container.xl">
          <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" align="center">
            <VStack align={{ base: 'center', md: 'flex-start' }} mb={{ base: 4, md: 0 }}>
              <Text fontSize="lg" fontWeight="bold">Portal GoyaEduca</Text>
              <Text color={textColor}>&copy; 2024 Derechos reservados</Text>
            </VStack>
            <Stack direction="row" spacing={4}>
              <Link href="#" isExternal>
                <IoLogoFacebook size={24} />
              </Link>
              <Link href="#" isExternal>
                <IoLogoXbox size={24} />
              </Link>
              <Link href="#" isExternal>
                <IoLogoLinkedin size={24} />
              </Link>
              <Link href="mailto:itg@goya.edu.co" isExternal>
                <IoMail size={24} />
              </Link>
            </Stack>
          </Flex>
        </Container>
      </Box>
    )
  }