import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  List,
  ListItem,
  Spinner,
  Stack,
  Text,
  Toast,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  IoSchool,
  IoBusinessOutline,
  IoTimeOutline,
  IoDesktopOutline,
} from "react-icons/io5";
import ApiResponse from "../../services/ApiResponse";
import { Carrera } from "../../services/models/Carrera";
import { useNavigate } from "react-router-dom";
import UnauthorizedPage from "../UnauthorizedPage";
import ServerErrorPage from "../ServerErrorPage";

const HomePage = () => {
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const cardBg = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.600", "gray.300");
  const [response, setResponse] = useState(new ApiResponse<Carrera[]>());
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCarreras = async () => {
      const apiResponse = new ApiResponse<Carrera[]>();
      await apiResponse.useFetch("carreras", "GET");
        setResponse(apiResponse);
    };
    fetchCarreras();
  }, []);

    let contentLo = () => {
      return (
        <Flex justify="center" align="center">
          <Spinner size="xl" thickness="4px" speed="0.65s" color="green.300" />
        </Flex>
      );
    };
  
    let contentOk = () => {
      return(
        <Box bg={cardBg} p={6} borderRadius="lg" shadow="md">
        <Heading size="lg" mb={4}>
          Carreras destacadas
        </Heading>
        <List spacing={4}>
          {response.data?.map((degree) => (
            <ListItem onClick={() => navigate(`/carreras/${degree.id}`)}
              cursor={"pointer"}
              key={degree.id}
              p={4}
              bg={bgColor}
              borderRadius="md"
              _hover={{ transform: "translateX(8px)" }}
              transition="transform 0.2s"
            >
              <Text fontWeight="bold" fontSize="lg">
                {degree.nombre}
              </Text>
              <Stack
                direction={{ base: "column", sm: "row" }}
                spacing={8}
                mt={2}
              >
                <Text color={textColor}>
                  <IoBusinessOutline
                    style={{ display: "inline", marginRight: "8px" }}
                  />
                  <Text as="span" fontWeight="semibold">
                    Institucion:
                  </Text>{" "}
                  {degree.institucion_nombre}
                </Text>
                <Text color={textColor}>
                  <IoTimeOutline
                    style={{ display: "inline", marginRight: "8px" }}
                  />
                  <Text as="span" fontWeight="semibold">
                    Duracion:
                  </Text>{" "}
                  {degree.duracion_anios > 0 &&
                    `${degree.duracion_anios} ${
                      degree.duracion_anios === 1 ? "año" : "años"
                    }`}{" "}
                  {degree.duracion_anios > 0 &&
                    degree.duracion_meses > 0 &&
                    "y "}
                  {degree.duracion_meses > 0 &&
                    `${degree.duracion_meses} ${
                      degree.duracion_meses === 1 ? "mes" : "meses"
                    }`}
                </Text>
                <Text color={textColor}>
                  <IoDesktopOutline
                    style={{ display: "inline", marginRight: "8px" }}
                  />
                  <Text as="span" fontWeight="semibold">
                    Modalidad:
                  </Text>{" "}
                  {degree.modalidad}
                </Text>
              </Stack>
            </ListItem>
          ))}
        </List>
      </Box>);
    };
  
    let contentUn = () => {
      return <UnauthorizedPage></UnauthorizedPage>;
    };
  
    let contentSe = () => {
      return <ServerErrorPage></ServerErrorPage>;
    };
  
    const content = {
      lo: contentLo(),
      ok: contentOk(),
      un: contentUn(),
      se: contentSe(),
    };
  
    const getContentType = () => {
      if (response.loading === true) {
        return content.lo;
      } else {
        if (response.responseCode === 200) {
          return content.ok;
        } else if (response.responseCode === 401) {
          return content.un;
        } else if (response.responseCode === 999) {
          return content.se;
        } else {
          return content.se;
        }
      }
    };

  return (
    <Box bg={bgColor} minH="100vh" py={10}>
      <Container maxW="container.xl">
        <VStack spacing={8} align="stretch">
          <Heading size="2xl" textAlign="center" mb={1}>
            Bienvenido al portal Goya Educa
          </Heading>
          <Text
            color="gray.600"
            fontWeight="semibold"
            textStyle="xl"
            textAlign="center"
            mb={4}
          >
            Explora las instituciones y carreras disponibles en Goya Educa.
          </Text>

          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={4}
            justify="center"
            mb={8}
          >
            <Button
              onClick={() => navigate("/carreras")}
              size="lg"
              colorScheme="teal"
              leftIcon={<IoSchool />}
              px={8}
            >
              Ver carreras disponibles
            </Button>
            <Button
              onClick={() => navigate("/instituciones")}
              size="lg"
              colorScheme="purple"
              leftIcon={<IoBusinessOutline />}
              px={8}
            >
              Explorar instituciones
            </Button>
          </Stack>
          {getContentType()}
        </VStack>
      </Container>
    </Box>
  );
};

export default HomePage;
