import React, { useEffect, useState } from "react";
import TableCRUD from "../../../components/TableCRUD";
import { Institucion } from "../../../services/models/Institucion";
import ApiResponse from "../../../services/ApiResponse";
import SuperUserDashboard from "../SuperUserDashboard";
import { Box, Flex, Spinner } from "@chakra-ui/react";
import UnauthorizedPage from "../../UnauthorizedPage";
import ServerErrorPage from "../../ServerErrorPage";

function InstitucionesTablePage() {
  const headers = [
    "ID",
    "CUE",
    "CUE Anexo",
    "Nombre",
    "Dirección",
    "Ubicación Lat",
    "Ubicación Long",
    "Logo",
    "Teléfono",
    "Página",
    "Gestión",
  ];

  const [response, setResponse] = useState(new ApiResponse<Institucion[]>());
  useEffect(() => {
    const fetchInstituciones = async () => {
      const apiResponse = new ApiResponse<Institucion[]>();
      await apiResponse.useFetch("instituciones", "GET");
      if (apiResponse.data) {
        apiResponse.data.sort((a, b) => a.nombre.localeCompare(b.nombre));
      }
      setResponse(apiResponse);
      console.log("apiResponse", apiResponse);
    };

    fetchInstituciones();
  }, []);

  let contentLo = () => {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" thickness="4px" speed="0.65s" color="green.300" />
      </Flex>
    );
  };

  let contentOk = () => {
    return (
      <TableCRUD
        tableName="Instituciones"
        headers={headers}
        data={response.data || []}
        infoColumn="nombre"
      />
    );
  };

  let contentUn = () => {
    return <UnauthorizedPage></UnauthorizedPage>;
  };

  let contentSe = () => {
    return <ServerErrorPage></ServerErrorPage>;
  };

  const content = {
    lo: contentLo(),
    ok: contentOk(),
    un: contentUn(),
    se: contentSe(),
  };

  const getContentType = () => {
    if (response.loading === true) {
      return content.lo;
    } else {
      if (response.responseCode === 200) {
        return content.ok;
      } else if (response.responseCode === 401) {
        return content.un;
      } else if (response.responseCode === 999) {
        return content.se;
      } else {
        return content.se;
      }
    }
  };

  return (
    <Box p={5} minH={"100vh"}>
      {getContentType()}
    </Box>
  );
}

export default InstitucionesTablePage;
