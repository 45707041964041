import React, { useRef, useEffect, useState } from "react";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { Input } from "@chakra-ui/react";

interface AutocompleteProps {
  onPlaceSelect: (place: google.maps.places.PlaceResult) => void;
  selectedLng: (lng: number) => void;
  selectedLat: (lat: number) => void;
  setAddress: (address: string) => void;
  defaultAddress?: string;
}

export const Autocomplete: React.FC<AutocompleteProps> = ({
  onPlaceSelect,
  selectedLng,
  selectedLat,
  setAddress,
  defaultAddress,
}) => {
  const [placeAutocomplete, setPlaceAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const places = useMapsLibrary("places");

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      componentRestrictions: { country: "AR" },
      fields: ["geometry.location", "name", "formatted_address", "address_components"],
      types: ["address"],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    const listener = placeAutocomplete.addListener("place_changed", () => {
      const place = placeAutocomplete.getPlace();
      onPlaceSelect(place);
      if (place.geometry && place.geometry.location) {
        selectedLat(place.geometry.location.lat());
        selectedLng(place.geometry.location.lng());
        setAddress(handleDataAutocomplete(place.address_components || []));
      }
    });

    return () => {
      if (listener) listener.remove();
    };
  }, [onPlaceSelect, placeAutocomplete, selectedLat, selectedLng, setAddress]);

  function handleDataAutocomplete(addressComponents: google.maps.GeocoderAddressComponent[]): string {
    let streetName = "";
    let streetNumber = "";
    let city = "";
    let province = "";

    addressComponents.forEach((component) => {
      if (component.types.includes("route")) {
        streetName = component.long_name;
      }
      if (component.types.includes("street_number")) {
        streetNumber = component.long_name;
      }
      if (component.types.includes("administrative_area_level_2") && component.types.includes("political")) {
        city = component.long_name;
      }
      if (component.types.includes("administrative_area_level_1") && component.types.includes("political")) {
        province = component.long_name;
      }
    });

    return `${streetName} ${streetNumber}, ${city}, ${province}`;
  }

  function handleChange() {
    setAddress("");
  }

  return (
    <div className="autocomplete-container">
      <div className="relative">
        <Input
          id="address"
          ref={inputRef}
          required
          onChange={handleChange}
          defaultValue={defaultAddress || ""}
          placeholder=""
        />
      </div>
    </div>
  );
};
