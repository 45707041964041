import {
  Box,
  Heading,
  Grid,
  GridItem,
  VStack,
  Text,
  Image,
  Accordion,
  AccordionPanel,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
} from "@chakra-ui/react";

const InfoPage = () => {
  const creators = [
    {
      id: 1,
      name: "Thomas",
      role: "Desarrollador",
      photo: "/images/thomas.png",
    },
    {
      id: 2,
      name: "Ludmila",
      role: "Desarrolladora",
      photo: "/images/ludmila.png",
    },
    {
      id: 3,
      name: "Marco",
      role: "Coordinador",
      photo: "/images/marco.png",
    },
  ];

  return (
    <Box
      p={5}
      bgColor="#042C3C"
      color="cyan.100"
      textAlign="center"
      minHeight="100vh"
    >
      <Text
        mb={4}
        fontSize={{ base: "lg", md: "xl" }}
        color="cyan.200"
        maxWidth="700px"
        mx="auto"
      >
        "Por estudiantes, para futuros estudiantes..."
      </Text>
      <Text
        mb={8}
        fontSize={{ base: "md", md: "lg" }}
        color="cyan.200"
        maxWidth="700px"
        mx="auto"
      >
        "Desarrollado íntegramente por estudiantes de la Tecnicatura
        Universitaria en Programación de UTN en Goya."
      </Text>
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
        }}
        gap={8}
        px={{ base: 2, md: 8 }}
      >
      </Grid>
    </Box>
  );
};

export default InfoPage;
