import config from "../MapConfig";
const API_BASE_URL = config.API_BASE_URL;
class ApiResponse<T> {
  data: T | null = null;
  error: string | null = null;
  loading: boolean = true;
  responseCode: number | null = null;

  async useFetch(
    url: string,
    method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE" = "GET",
    body: any = null,
    isMultipart: boolean = false,
    headers: HeadersInit = { "Content-Type": "application/json" }
  ): Promise<void> {
    this.loading = true;

    const token = localStorage.getItem("token");
    let response;

    if (token) {
      headers = {
        ...headers,
        Authorization: `Bearer ${token}`,
      };
    }

    let finalBody = null;
    if (body) {
      if (isMultipart) {
        finalBody = body;
        headers = {
          Authorization: `Bearer ${token}`,
        };
      } else {
        finalBody = JSON.stringify(body);
      }
    }

    let responseCode = 999;
    try {
      response = await fetch(`${API_BASE_URL}${url}`, {
        method,
        headers,
        body: finalBody,
      });
      responseCode = response.status;
    } catch (error: any) {
      this.setResponseCode(responseCode);
      this.setError(error.message);
      return;
    }

    const contentLength = response.headers.get("content-length");
    const contentType = response.headers.get("content-type");
    const hasJsonContent =
      contentLength !== "0" && contentType?.includes("application/json");

    try {
      if (hasJsonContent) {
        const jsonData = await response.json();
        if (response.ok) {
          this.setData(jsonData as T);
        } else {
          this.setError(jsonData);
        }
      } else if (response.ok) {
        this.setData(null as T);
      } else {
        throw new Error("Respuesta del servidor inesperada");
      }
      this.setResponseCode(responseCode);
    } catch (error) {
      this.setError(
        error instanceof Error ? error.message : "Error inesperado"
      );
      this.setResponseCode(responseCode);
    }
  }

  setData(data: T) {
    this.data = data;
    this.loading = false;
  }

  setError(error: any) {
    this.error = error;
    this.loading = false;
  }

  setResponseCode(code: number) {
    this.responseCode = code;
  }
}

export default ApiResponse;
