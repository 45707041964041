import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  Heading,
  useToast,
  Text,
  Image,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import SuperUserDashboard from "../SuperUserDashboard";
import { Gestiones, Institucion } from "../../../services/models/Institucion";
import LogoFileInput from "../../../components/LogoFileInput";
import PhoneNumberInputArg from "../../../components/PhoneNumberInputArg";
import UrlInput from "../../../components/UrlInput";
import MapComponent from "../../../components/MapComponent";
import { Autocomplete } from "../../../components/AutoComplete";
import ApiResponse from "../../../services/ApiResponse";
import config from "../../../MapConfig";
import { ApiValidationResponse } from "../../../services/models/ApiValidationResponse";
import handleApiError from "../../../utils/ApiErrorHandler";

const InstitucionAddPage = () => {
  const navigate = useNavigate();
  const cueRef = useRef<HTMLInputElement>(null);
  const cueanexoRef = useRef<HTMLInputElement>(null);
  const nombreRef = useRef<HTMLInputElement>(null);
  const direccionRef = useRef<HTMLInputElement>(null); //alpedo ahora
  const ubicacionLatRef = useRef<HTMLInputElement>(null); //alpedo ahora
  const ubicacionLongRef = useRef<HTMLInputElement>(null); //alpedo ahora
  const telRef = useRef<HTMLInputElement>(null);
  const paginaRef = useRef<HTMLInputElement>(null);
  const [gestion, setGestion] = useState<Gestiones>(Gestiones.Publica);
  const gestionRef = useRef<HTMLSelectElement>(null);
  const logoRef = useRef<HTMLInputElement>(null);
  const toast = useToast();
  const params = useParams();

  const [latError, setLatError] = useState("");
  const [longError, setLongError] = useState("");
  const [direccionError, setDireccionError] = useState("");

  interface LatLng {
    lat: number;
    lng: number;
  }

  const [lat, setLat] = useState<number>(-29.14383470536903);
  const [lng, setLng] = useState<number>(-59.26513140291266);

  const [direccion, setDireccion] = useState<string>("");

  const [selected, setSelected] =
    useState<google.maps.places.PlaceResult | null>(null);
  const [defaultAddress, setDefaultAddress] = useState<string | null>(null);

  const [institucionLogo, setInstitucionLogo] = useState<string>("");

  function handleDataAutocomplete(address: string): void {
    setDireccion(address);
  }

  const handleDataFromMap = (data: LatLng) => {
    setLat(data.lat);
    setLng(data.lng);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const lati = lat;
    const longi = lng;
    let valid = true;

    if (direccion === "") {
      setDireccionError("Ingrese una direccion valida");
      valid = false;
    }

    if (lati && (isNaN(lati) || lati < -90 || lati > 90)) {
      setLatError("La latitud debe estar entre -90 y 90.");
      valid = false;
    } else {
      setLatError("");
    }

    if (longi && (isNaN(longi) || longi < -180 || longi > 180)) {
      setLongError("La longitud debe estar entre -180 y 180.");
      valid = false;
    } else {
      setLongError("");
    }

    if(cueRef.current?.value.length !== 9){
      toast({
        title: 'Error al crear la institución.',
        description: 'El CUE debe tener 9 dígitos.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!valid) {
      return;
    }

    const logoFile = logoRef.current?.files?.[0];

    const formData = new FormData();
    formData.append("cue", cueRef.current?.value || "0");

    if (cueanexoRef.current?.value) {
      formData.append("cueanexo", cueanexoRef.current.value);
    }
    formData.append("nombre", nombreRef.current?.value || "");
    formData.append("direccion", direccion);
    if (lat) {
      formData.append("ubicacion_lat", lati.toString());
    }
    if (lng) {
      formData.append("ubicacion_long", longi.toString());
    }
    if (telRef.current?.value) {
      formData.append("tel", telRef.current.value);
    }
    if (paginaRef.current?.value) {
      formData.append("pagina", paginaRef.current.value);
    }

    formData.append("gestion", gestionRef.current?.value || Gestiones.Publica);

    if (logoFile) {
      formData.append("logo", logoFile);
    }

    const response = new ApiResponse();
    params.id
      ? await response.useFetch(
          `instituciones/${params.id}`,
          "PATCH",
          formData,
          true
        )
      : await response.useFetch("instituciones", "POST", formData, true);

    if (response.error == null) {
      toast({
        title: "Institución creada",
        description: params.id
          ? "La institucion fue actualizada con éxito"
          : "La institución fue creada con éxito.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate("/dashboard/instituciones");
    } else {
      if (typeof response.error === "object" && response.error !== null) {
        if ("errors" in response.error) {
          handleApiError(response.error as ApiValidationResponse, toast);
        } else if ("message" in response.error) {
          const { message } = response.error;
          toast({
            title: "Error",
            description: message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Error desconocido",
            description: "Ocurrió un problema inesperado.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        toast({
          title: params.id ? "Error al actualizar" : "Error al crear",
          description:
            response.error || "Ocurrió un problema realizar la operación.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const handleLatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //alpedo
    const value = e.target.value;
    if (value === "") {
      setLatError("");
    } else {
      const lat = parseFloat(value);
      if (isNaN(lat) || lat < -90 || lat > 90) {
        setLatError("La latitud debe estar entre -90 y 90.");
      } else {
        setLatError("");
      }
    }
  };

  const handleLongChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //alpedo
    const value = e.target.value;
    if (value === "") {
      setLongError("");
    } else {
      const long = parseFloat(value);
      if (isNaN(long) || long < -180 || long > 180) {
        setLongError("La longitud debe estar entre -180 y 180.");
      } else {
        setLongError("");
      }
    }
  };

  useEffect(() => {
    if (params.id) {
      const fetchInstituciondata = async () => {
        const response = new ApiResponse<Institucion>();
        await response.useFetch(`instituciones/${params.id}`, "GET");
        if (response.error == null && response.data) {
          if (nombreRef.current && response.data) {
            nombreRef.current.value = response.data.nombre;
          }
          if (cueRef.current && response.data) {
            cueRef.current.value = String(response.data.cue);
          }
          if (cueanexoRef.current && response.data) {
            cueanexoRef.current.value = String(response.data.cueanexo);
          }
          if (response.data?.direccion) {
            setDireccion(response.data.direccion);
            setDefaultAddress(response.data.direccion);
          }
          if (response.data?.ubicacion_lat) {
            setLat(response.data.ubicacion_lat);
          }
          if (response.data?.ubicacion_long) {
            setLng(response.data.ubicacion_long);
          }
          if (telRef.current && response.data?.tel) {
            telRef.current.value = response.data.tel;
          }
          if (paginaRef.current && response.data?.pagina) {
            paginaRef.current.value = response.data.pagina;
          }
          if (gestionRef.current && response.data?.gestion) {
            gestionRef.current.value = response.data.gestion;
          }
          if (response.data.logo) {
            setInstitucionLogo(response.data.logo);
          }
        } else {
          toast({
            title: "Error",
            description: `Ocurrió un error al cargar los datos de la institucion: ${response.error}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      };
      fetchInstituciondata();
    }
  }, [params.id]);

  return (
    <div>
      <Box maxW="2xl" mx="auto" mt={8} mb={8} p={6} borderWidth={1} borderRadius="lg" shadow={"md"}>
        <Heading mb={6} textAlign="center" size="lg">
          {params.id ? "Editar" : "Crear"} Institución
        </Heading>
        <form onSubmit={handleSubmit}>
          <VStack spacing={5}>
            <FormControl id="cue" isRequired>
              <FormLabel>CUE</FormLabel>
              <Input type="number" ref={cueRef} defaultValue={undefined} />
            </FormControl>

            <FormControl id="cueanexo">
              <FormLabel>CUE Anexo</FormLabel>
              <Input type="number" ref={cueanexoRef} defaultValue={undefined} />
            </FormControl>

            <FormControl id="nombre" isRequired>
              <FormLabel>Nombre</FormLabel>
              <Input type="text" ref={nombreRef} defaultValue="" />
            </FormControl>

            <FormControl id="direccion" isRequired>
              <FormLabel>Dirección</FormLabel>
              <Autocomplete
                onPlaceSelect={setSelected}
                setAddress={handleDataAutocomplete}
                selectedLng={setLng}
                selectedLat={setLat}
                defaultAddress={defaultAddress ? defaultAddress : ""}
              />
              {direccionError && <Text color="red.500">{direccionError}</Text>}
            </FormControl>

            <PhoneNumberInputArg telRef={telRef}></PhoneNumberInputArg>

            <UrlInput paginaRef={paginaRef}></UrlInput>

            <FormControl id="gestion" isRequired>
              <FormLabel>Gestión</FormLabel>
              <Select
                ref={gestionRef}
                value={gestion}
                onChange={(e) => setGestion(e.target.value as Gestiones)}
              >
                <option value={Gestiones.Publica}>Pública</option>
                <option value={Gestiones.Privada}>Privada</option>
                <option value={Gestiones.Mixta}>Mixta</option>
              </Select>
            </FormControl>

            <LogoFileInput logoRef={logoRef}></LogoFileInput>
            {institucionLogo !== "" && (
              <div>
                <Text fontSize="lg" fontWeight="bold">
                  Logo actual:
                </Text>
                <Image
                  src={config.API_IMAGES_URL + institucionLogo}
                  alt={`Imagen invalida`}
                  boxSize="40px"
                  objectFit="cover"
                  mt={2}
                />
              </div>
            )}

            <MapComponent
              onDataFromMap={handleDataFromMap}
              lati={lat}
              lngi={lng}
            ></MapComponent>
            {latError && <Text color="red.500">{latError}</Text>}
            {longError && <Text color="red.500">{longError}</Text>}

            <Button type="submit" colorScheme="teal" size="lg" width="full">
              {params.id ? "Guardar cambios" : "Crear institución"}
            </Button>
            <Button
              type="button"
              onClick={() => navigate("/dashboard/instituciones")}
              colorScheme="gray"
              size="lg"
              width="full"
            >
              Cancelar
            </Button>
          </VStack>
        </form>
      </Box>
    </div>
  );
};

export default InstitucionAddPage;
