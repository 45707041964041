import React, { useEffect, useState } from "react";
import AdminDashboard from "../AdminDashboard ";
import ApiResponse from "../../../services/ApiResponse";
import TableCRUD from "../../../components/TableCRUD";
import { getDecodedToken, TokenPayload } from "../../../services/Token";
import { Box, Flex, Spinner } from "@chakra-ui/react";
import { Carrera } from "../../../services/models/Carrera";
import UnauthorizedPage from "../../UnauthorizedPage";
import { Server } from "http";
import ServerErrorPage from "../../ServerErrorPage";

function CarrerasTablePage() {
  const headers = [
    "ID",
    "Nombre",
    "Tipo",
    "Descripción",
    "Plan de Estudio",
    "Modalidad",
    "Cupo",
    "Duración Años",
    "Duración Meses",
    "Fecha de Inscripción",
    "Observación",
    "Prioridad",
  ];
  const [response, setResponse] = useState(new ApiResponse<Carrera[]>());
  const decoded = getDecodedToken();
  useEffect(() => {
    const fetchInstituciones = async () => {
      const apiResponse = new ApiResponse<Carrera[]>();
      await apiResponse.useFetch(
        `carreras/institucion/${decoded?.institucion_id}`,
        "GET"
      );
      if (apiResponse.data) {
        apiResponse.data.sort((a, b) => a.nombre.localeCompare(b.nombre));
      }
      setResponse(apiResponse);
    };

    fetchInstituciones();
  }, []);

  let contentLo = () => {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" thickness="4px" speed="0.65s" color="green.300" />
      </Flex>
    );
  };

  let contentOk = () => {
    return (
      <TableCRUD
        tableName="Carreras"
        headers={headers}
        data={response.data || []}
        infoColumn="nombre"
      />
    );
  };

  let contentUn = () => {
    return <UnauthorizedPage></UnauthorizedPage>;
  };

  let contentSe = () => {
    return <ServerErrorPage></ServerErrorPage>;
  };

  const content = {
    lo: contentLo(),
    ok: contentOk(),
    un: contentUn(),
    se: contentSe(),
  };

  const getContentType = () => {
    if (response.loading === true) {
      return content.lo;
    } else {
      if (response.responseCode === 200) {
        return content.ok;
      } else if (response.responseCode === 401) {
        return content.un;
      } else if (response.responseCode === 999) {
        return content.se;
      } else {
        return content.se;
      }
    }
  };
  return (
    <Box p={5} minH={"100vh"}>
    {getContentType()}
    </Box>)
}
export default CarrerasTablePage;
