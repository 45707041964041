'use client'

import {
  Box,
  Button,
  Container,
  Heading,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react'
import { IoHomeOutline, IoArrowBackOutline } from "react-icons/io5"
import { Link } from 'react-router-dom'

export default function NotFoundPage() {
  const bgColor = useColorModeValue('gray.50', 'gray.900')
  const textColor = useColorModeValue('gray.600', 'gray.300')

  return (
    <Box bg={bgColor} minH="100vh" py={10}>
      <Container maxW="container.md">
        <VStack spacing={8} align="center" textAlign="center">
          <Heading size="4xl" color="red.500">
            404
          </Heading>
          <Heading size="xl">Pagina no encontrada</Heading>
          <Text fontSize="xl" color={textColor}>
            Oops! La pagina que estas buscando no existe o fue movida
          </Text>
          <Box>
            <Link to="/">
              <Button leftIcon={<IoHomeOutline />} colorScheme="teal" size="lg" mr={4}>
                Volver al inicio
              </Button>
            </Link>
            <Button leftIcon={<IoArrowBackOutline />} colorScheme="gray" size="lg" onClick={() => window.history.back()}>
              Volver a pagina anterior
            </Button>
          </Box>
        </VStack>
      </Container>
    </Box>
  )
}