import { AdvancedMarker, Map } from "@vis.gl/react-google-maps";
import config from "../MapConfig";
import { useCallback, useEffect, useState } from "react";

interface LatLng {
  lat: number;
  lng: number;
}

interface CameraProps {
  center: LatLng;
}

interface MapComponentProps {
  onDataFromMap: (data: LatLng) => void;
  lati: number;
  lngi: number;
}

const INITIAL_CAMERA: CameraProps = {
  center: { lat: -29.14383470536903, lng: -59.26513140291266 },
};

const INITIAL_MARKER = {
  position: { lat: -29.14383470536903, lng: -59.26513140291266 },
};

const MapComponent: React.FC<MapComponentProps> = ({ onDataFromMap, lati, lngi }) => {
  const MAP_ID = config.MAP_ID;
  const [center, setCenter] = useState<LatLng>({ lat: -29.14383470536903, lng: -59.26513140291266 });
  const [marker, setMarker] = useState<LatLng>({ lat: lati, lng: lngi });
  const [cameraProps, setCameraProps] = useState<CameraProps>(INITIAL_CAMERA);
  const [initialMarker, setInitialMarker] = useState<{ position: LatLng }>(INITIAL_MARKER);

  useEffect(() => {
    if (lati && lngi) {
      const lat = parseFloat(String(lati));
      const lng = parseFloat(String(lngi));
      if (lat && lng) {
        setCenter({ lat, lng });
        setInitialMarker({ position: { lat, lng } });
        setCameraProps({ center: { lat, lng } });
      }
    }
  }, [lati, lngi]);

  const handleCameraChange = useCallback((ev: any) => {
    setCameraProps(ev.detail as CameraProps);
  }, []);

  const handleMapClick = (event: any) => {
    const lat = event.detail.latLng.lat;
    const lng = event.detail.latLng.lng;
    const newPosition = { lat, lng };
    setInitialMarker({ position: newPosition });
    setMarker(newPosition);
    onDataFromMap(newPosition);
  };

  return (
    <Map
      defaultZoom={18}
      defaultCenter={{ lat: center.lat, lng: center.lng }}
      mapId={MAP_ID}
      {...cameraProps}
      onCameraChanged={handleCameraChange}
      style={{ height: "50vh" }}
      onClick={handleMapClick}
    >
      {marker.lat && marker.lng && <AdvancedMarker {...initialMarker} />}
    </Map>
  );
};

export default MapComponent;