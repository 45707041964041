import React from "react";
import {
  AdvancedMarker,
  InfoWindow,
  Map,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import config from "../MapConfig";

interface InstitucionDetailMapProps {
  lati?: string | number; 
  lngi?: string | number; 
  address?: string;        
  nombre: string;          
}

const InstitucionDetailMap: React.FC<InstitucionDetailMapProps> = ({
  lati,
  lngi,
  address,
  nombre,
}) => {
  const MAP_ID = config.MAP_ID;
  const [markerRef, markerA] = useAdvancedMarkerRef();

  return (
    <Map
      defaultZoom={17}
      defaultCenter={
        lati
          ? { lat: parseFloat(lati as string), lng: parseFloat(lngi as string) }
          : { lat: -29.14383470536903, lng: -59.26513140291266 }
      }
      mapId={MAP_ID}
      style={{ minHeight: "100%", minWidth: "100%" }}
    >
      {lati && lngi && (
        <AdvancedMarker
          ref={markerRef}
          position={{ lat: parseFloat(lati as string), lng: parseFloat(lngi as string) }}
        />
      )}
      {address && (
        <InfoWindow
          headerContent={
            <h1 className="text-base font-extrabold text-gray-950">{nombre}</h1>
          }
          style={{
            backgroundColor: "white",
            padding: "5px",
            color: "black",
          }}
          anchor={markerA}
        >
          <h4 className="from-neutral-700">{address}</h4>
        </InfoWindow>
      )}
    </Map>
  );
};

export default InstitucionDetailMap;